import { render, staticRenderFns } from "./SectionContactV2.vue?vue&type=template&id=6c5f24e2&"
import script from "./SectionContactV2.vue?vue&type=script&lang=js&"
export * from "./SectionContactV2.vue?vue&type=script&lang=js&"
import style0 from "./SectionContactV2.vue?vue&type=style&index=0&id=6c5f24e2&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OrganismsProjectOrganismContactForm: require('/app/components/organisms/project/OrganismContactForm.vue').default,AtomsCommonAtomIcon: require('/app/components/atoms/common/AtomIcon.vue').default,AtomsPortfolioAtomContact: require('/app/components/atoms/portfolio/AtomContact.vue').default})
