
export default {
  name: 'SectionContactV2',
  props: {
    data: {
      required: true,
      type: Object,
      default: function () {
        return {}
      }
    },
    orderNo: {
      required: true,
      type: Number,
      default: 0
    },
    hash: {
      required: true,
      type: String,
      default: ''
    }
  },
  computed: {
    generalConfig() {
      return this.$store.state.base.meta.generalConfig
    },
    instagramUrl() {
      if (!this.generalConfig.social) return ''
      return this.generalConfig.social.instagramUrl
    },
    linkedinUrl() {
      if (!this.generalConfig.social) return ''
      return this.generalConfig.social.linkedinUrl
    },
    facebookUrl() {
      if (!this.generalConfig.social) return ''
      return this.generalConfig.social.facebookUrl
    }
  }
}
