
// import config from "../../../config";
export default {
  name: 'AtomContact',
  components: {},
  props: {
    image: {
      required: false,
      type: String,
      default: ''
    },
    name: {
      required: true,
      type: String,
      default: ''
    },
    nr: {
      required: true,
      type: String,
      default: ''
    },
    email: {
      required: true,
      type: String,
      default: ''
    },
    position: {
      required: true,
      type: String,
      default: ''
    },
    urlLinkedin: {
      required: true,
      type: String,
      default: 'javascript:void(0)'
    }
  },
  computed: {
    client() {
      return config.CLIENT
    }
  },
  methods: {
    handleTracking() {
      if (config.CLIENT === 'one' && config.RUNTIME_ENV === 'production' && 'lintrk' in window) {
        window.lintrk('track', { conversion_id: 4403868 })
      }
    },
    resourceUrl(img) {
      return `${this.$store.getters['base/cdnBase']}/${img}`
    }
  }
}
